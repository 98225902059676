body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
    box-sizing: border-box;
    width: 70%;
    margin: 0 auto;
    padding: 10px 30px 100px 30px;
    min-width: 700px;
    height: 100%;
    overflow-x: hidden;
}

.content_container {
    height: 100%;
}

.module_header {
    text-align: center;
    font-size: 16px !important;
    color: black !important;
}

code {
  font-family: "Courier New",
    monospace;
}
